import Vue from 'vue'
import App from './App.vue'
import './service/registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from './config'
import VueAxios from 'vue-axios'
import swal from 'sweetalert';
import lodash from "lodash";
import numeral from "numeral";
import Router from 'vue-router';
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.prototype.$swal = swal;
Vue.prototype.$_ = lodash;
Vue.prototype.$numeral = lodash;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
Vue.filter("numberFormat", function (value) {
  return numeral(value).format('0,0');
});
Vue.filter("appName", function (value) {
  return "Kaung Myat Denti";
});

window.OneSignal = window.OneSignal || [];
var initConfig = {
  appId: "5939d585-7262-46b1-a241-01f5c45bd433343" //K10 error
};
OneSignal.push(function () {
  OneSignal.SERVICE_WORKER_PARAM = { scope: '/js/' };
  OneSignal.SERVICE_WORKER_PATH = 'js/OneSignalSDKWorker.js'
  OneSignal.SERVICE_WORKER_UPDATER_PATH = 'js/OneSignalSDKUpdaterWorker.js'
  OneSignal.init(initConfig);
});
