import axios from "../config"

class UserAccountService{
    axios;
    constructor(){
        this.axios = axios;
    }
    changePassword(currentPassword, newPassword) {
      let url = `userAccounts/changepassword`
      return this.axios.get(url,
        {
          params: {
            currentPassword,
            newPassword
          }
        }).then(request => request.data);
    }
    getUserOwn() {
      let url = "/userAccounts/own";
      return axios.get(url).then(request => request.data);
    }
    getUserAccounts(userType) {
      let url = `/useraccounts`
      return axios.get(url,{
          params:{
              userType
          }
      }).then(request => request.data);
    }
    getOnesignalAccount(){
      let url = `/onesignalAccount`;
      return this.axios.get(url).then(request => request.data);
    }
}

const service = new UserAccountService();
export default service;